import { Component, OnInit, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';

import { AppDataService } from './app.dataservice';
import { State, Applicant, JobTitle, Certification, Position, ResultStatus, ResultEntity, Upload, Company } from './shared/models';
import { Form, NgForm } from '@angular/forms';

import * as $ from 'jquery';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [AppDataService],
    styles: []
})
export class AppComponent implements OnInit, AfterViewChecked {

    @ViewChild('btnCancel') btnCancel: ElementRef;
    tabIndex: number = 0; // which tab is visible/active
    tabs: Array<string> = ["Personal Information", "Preferred Work Locations", "Work Experience", "Training and Certifications", "Applying For", "Companies", "Upload Resume"];
    previousWorkExperience: boolean = false;
    previousInspectionExperience: boolean = false;
    isFinished: boolean = false;
    isSaved: boolean = false;
    states: Array<State> = [];
    jobTitles: Array<JobTitle> = [];
    certifications: Array<Certification> = [];
    companies: Array<Company> = [];
    positions: Array<Position> = [];
    applicant: Applicant = new Applicant();
    searchLastName: string = "";
    searchPhone: string = "";
    searchMessageError: string;
    uploadResumeError: string;
    uploadResumeSuccess: string;
    uploadCertError: string;
    uploadCertSuccess: string;
    validationMessage: string;

    constructor(private _http: AppDataService) { }

    ngAfterViewChecked(): void {
        let self = this;
        $("input[name='inputMobilePhone'],input[name='inputHomePhone']").keyup(function () {
            $(this).val(String($(this).val()).replace(/^(\d{3})(\d{3})((\d)+)$/, "($1) $2-$3"));
        });
    }

    ngOnInit() {
        // load all dropdown list data
        this._http.getStates()
            .subscribe(result => {
                if (result.status == ResultStatus.success) {
                    this.states = result.value as Array<State>;
                }
                else {
                    console.error("error occurred retrieving states: " + String(result.value));
                }
            });

        this._http.getJobTitles()
            .subscribe(result => {
                this.jobTitles = result.value as Array<JobTitle>;
            });

        this._http.getCertifications()
            .subscribe(result => {
                this.certifications = result.value as Array<Certification>;
            });

        this._http.getCompanies()
            .subscribe(result => {
                this.companies = result.value as Array<Company>;
            });

        this._http.getPositions()
            .subscribe(result => {
                this.positions = result.value as Array<Position>;
                this.positions.forEach((el) => { el.checked = false; });
            });
    }

    onTabClicked(id: number): void {
        this.changeTabIndex(id);
    }

    btnPrevious_click(): void {
        this.changeTabIndex(this.tabIndex - 1);
    }

    btnNext_click(): void {
        this.changeTabIndex(this.tabIndex+1);
    }

    changeTabIndex(newIndex: number) {
        // if switching away from the personal info tab, try to geocode the address
        if (this.tabIndex == 0) {
            // geocode address
            var geocoder: google.maps.Geocoder = new google.maps.Geocoder();
            var address = this.applicant.address + ', ' + this.applicant.city + ', ' + this.applicant.state.stateName;
            var t = this;
            geocoder.geocode({ 'address': address }, function (results, status) {
                if (status == 'OK') {
                    t.applicant.lat = results[0].geometry.location.lat();
                    t.applicant.lng = results[0].geometry.location.lng();
                }
            });
        }

        this.tabIndex = newIndex;
    }
    
    onSubmit(f: NgForm): void {
        var saveOnly: Boolean = f.value.submit == "save";
        var isFormValid: Boolean = true;

        if (saveOnly) { // only have to verify last name and phone
            isFormValid = f.form.controls.lastName.valid &&
                (f.form.controls.inputMobilePhone.valid || f.form.controls.inputHomePhone.valid)
            if (!isFormValid) {
                this.validationMessage = 'Please enter a last name and at least one phone number to save your application.';
                return;
            }
        }

        this.validationMessage = this.validateApplicant();

        if ((isFormValid && !this.validationMessage) || saveOnly) {
            this.populateApplicant();

            if (saveOnly) {
                this.applicant.applicationDate = null;
            }
            else {
                this.applicant.applicationDate = (new Date()).toDateString(); // today's date
            }

            this._http.saveApplication(this.applicant)
                .subscribe(result => {
                    if (result.status == ResultStatus.success) {
                        if (saveOnly) {
                            this.isSaved = true;
                        }
                        else {
                            this.isFinished = true;
                        }
                    }
                    else if (result.status == ResultStatus.warning) {
                        this.validationMessage = String(result.value);
                    }
                    else {
                        this.validationMessage = "An error occurred trying to submit your application. Please try again or contact us for help.";
                    }
                });
        }
    }

    validateApplicant(): string {
        var a: Applicant = this.applicant; // shortcut

        // Personal Information tab
        if (!a.firstName || !a.lastName || !a.address || !a.city || !a.state.stateID || !a.zip) {
            return "Personal Information - please make sure that the first/last name and address fields have all been filled out.";
        }
        if (!a.email || !this.validateEmail(a.email)) {
            return "Personal Information - please make sure you have entered a valid email.";
        }
        if ((!a.mobilePhone && !a.homePhone) || (!this.validatePhone(a.mobilePhone) && !this.validatePhone(a.homePhone))) {
            return "Personal Information - please make sure you have entered at least one valid 10-digit phone number.";
        }

        // Preferred Work Locations tab
        var found: Boolean = false;
        if (!a.willWorkAnywhere) {
            // check to see if at least one state was selected
            for (let s of a.workLocations) {
                if (s.stateID) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                return "Preferred Work Locations - please select 'Work Anywhere' or select at least one location where you are able to work.";
            }
        }
        if (a.workedForAudiePrice == null) {
            return "Preferred Work Locations - please select Yes or No if you have worked for Audie Price Inspections before.";
        }

        // Work Experience tab
        if (a.previousInspectionExperience && !a.inspectionYears) {
            return "Work Experience - please enter number of years of inspection experience.";
        }
        if (a.previousPipelineExperience && !a.pipelineYears) {
            return "Work Experience - please enter number of years of pipeline experience.";
        }
        if (!this.validateJobHistory()) {
            return "Work Experience - please enter at least one recent job.";
        }
        if (a.isVeteran == null) {
            return "Work Experience - please select Yes or No if you are a veteran.";
        }

        // Training and Certifications tab
        if (this.awsCertNumber() && !a.awscwiCertNumber) {
            return "Training and Certifications - please enter your AWS/CWI Certification number.";
        }

        // Applying For tab
        if (!a.dateAvailable) {
            return "Applying For - please enter the date you are available to start work.";
        }

        // Upload tab
        found = false;
        for (let u of a.uploads) {
            if (u.uploadType == 0) {
                found = true;
            }
        }
        if (!found) {
            return "Upload Resume - please upload at least one file for your resume.";
        }

        return "";
    }

    validateJobHistory(): Boolean {
        var found: Boolean = false;
        for (let wh of this.applicant.workHistory) {
            if (wh.companyName && wh.jobTitle.jobTitleID && wh.fromDate && wh.toDate) {
                found = true;
                break;
            }
        }
        return found;
    }

    validateEmail(email) {
        var re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        return re.test(String(email).toLowerCase());
    }

    validatePhone(phone) {
        var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return re.test(String(phone).toLowerCase());
    }

    btnSearch_click(): void {
        this.searchMessageError = "";
        this._http.getSavedApplication(this.searchLastName, this.searchPhone)
            .subscribe(result => {
                if (result.status == ResultStatus.success) {
                    this.applicant = result.value as Applicant;
                    this.populateCheckboxFields();
                    this.btnCancel.nativeElement.click();

                }
                else if (result.status == ResultStatus.warning) {
                    this.searchMessageError = String(result.value);
                }
            });
    }

    awsCertNumber(): boolean {
        if (this.certifications) {
            for (var i: number = 0; i < this.certifications.length; i++) {
                if (this.certifications[i].certificationName == "AWS/CWI Certification") {
                    return this.certifications[i].checked;
                }
            }
        }
        return false;
    }

    populateApplicant(): void {
        // clear home phone if they checked "same as mobile" checkbox
        this.applicant.homePhone = this.applicant.isHomeSameAsMobile ? "" : this.applicant.homePhone;

        // clear work locations if they checked "work anywhere"
        if (this.applicant.willWorkAnywhere) {
            this.applicant.workLocations = [new State(), new State(), new State(), new State()];
        }

        // populate certs
        this.applicant.certifications = []; // clear
        for (var i: number = 0; i < this.certifications.length; i++) {
            if (this.certifications[i].checked) {
                this.applicant.certifications.push(this.certifications[i]);
            }
        }

        // populate companies
        this.applicant.companies = []; // clear
        for (var i: number = 0; i < this.companies.length; i++) {
            if (this.companies[i].checked) {
                this.applicant.companies.push(this.companies[i]);
            }
        }

        // populate positions
        this.applicant.positions = [];
        for (var i: number = 0; i < this.positions.length; i++) {
            if (this.positions[i].checked) {
                this.applicant.positions.push(this.positions[i]);
            }
        }
    }

    populateCheckboxFields(): void {
        for (var c: number = 0; c < this.applicant.certifications.length; c++) {
            for (var i: number = 0; i < this.certifications.length; i++) {
                if (this.certifications[i].certificationID == this.applicant.certifications[c].certificationID) {
                    this.certifications[i].checked = true;
                }
            }
        }

        for (var c: number = 0; c < this.applicant.companies.length; c++) {
            for (var i: number = 0; i < this.companies.length; i++) {
                if (this.companies[i].companyID == this.applicant.companies[c].companyID) {
                    this.companies[i].checked = true;
                }
            }
        }

        for (var c: number = 0; c < this.applicant.positions.length; c++) {
            for (var i: number = 0; i < this.positions.length; i++) {
                if (this.positions[i].positionID == this.applicant.positions[c].positionID) {
                    this.positions[i].checked = true;
                }
            }
        }

    }

    getUploads(uploadType: number): Array<Upload> {
        var arr: Array<Upload> = [];
        for (let upload of this.applicant.uploads) {
            if (upload.uploadType == uploadType) {
                arr.push(upload);
            }
        }
        return arr;
    }

    handleFileInput(files, uploadType): void {
        this.uploadResumeError = "";
        this.uploadResumeSuccess = "";
        this.uploadCertError = "";
        this.uploadCertSuccess = "";
        const formData = new FormData();

        for (let file of files)
            formData.append(file.name, file);

        this._http.uploadFile(formData, uploadType)
            .subscribe(result => {
                if (result.status == ResultStatus.success) {
                    this.applicant.uploads.push(result.value as Upload);
                    if (uploadType == 0) {
                        this.uploadResumeSuccess = "Successfully uploaded file";
                    }
                    else {
                        this.uploadCertSuccess = "Successfully uploaded file";
                    }
                }
                else if (result.status == ResultStatus.warning) {
                    if (uploadType == 0) {
                        this.uploadResumeError = String(result.value);
                    }
                    else {
                        this.uploadCertError = String(result.value);
                    }

                }
            });
    }
}
