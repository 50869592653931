﻿export class ResultEntity {
    value: object;
    status: ResultStatus;
    message: string;
}

export enum ResultStatus {
    success = 0,
    warning,
    failure
}

export class State {
    stateID: number;
    stateName: string;
    stateAbbr: string;
}

export class Applicant {
    applicantID: number;
    firstName: string;
    lastName: string;
    preferredName: string;
    fullName: string;
    address: string;
    lat: number;
    lng: number;
    city: string;
    state: State;
    zip: string;
    mobilePhone: string;
    homePhone: string;
    isHomeSameAsMobile: boolean;
    email: string;
    workedForAudiePrice: boolean;
    willWorkAnywhere: boolean;
    previousPipelineExperience: boolean;
    pipelineYears: number;
    previousInspectionExperience: boolean;
    inspectionYears: number;
    awscwiCertNumber: string;
    dateAvailable: string;
    experienceScore: string;
    applicationDate: string;
    comments: string;
    isVeteran: boolean;

    workLocations: Array<State>;
    certifications: Array<Certification>;
    companies: Array<Company>;
    positions: Array<Position>;
    workHistory: Array<WorkHistory>;
    uploads: Array<Upload>;

    constructor() {
        // prepopulate 4 states
        this.workLocations = [new State(), new State(), new State(), new State()];
        this.workHistory = [new WorkHistory(), new WorkHistory(), new WorkHistory(), new WorkHistory()];
        this.state = new State();
        this.uploads = [];
    }
}

export class WorkHistory {
    workHistoryID: number;
    companyName: string;
    jobTitle: JobTitle;
    fromDate: string;
    toDate: string;

    constructor() {
        this.jobTitle = new JobTitle();
    }
}

export class JobTitle {
    jobTitleID: number;
    jobTitleName: string;
}

export class Certification {
    certificationID: number;
    certificationName: string;
    checked: boolean;
}

export class Company {
    companyID: number;
    companyName: string;
    checked: boolean;
}

export class Position {
    positionID: number;
    positionName: string;
    checked: boolean;
}

export class Upload {
    uploadID: number;
    uploadType: number;
    azureFileName: string;
    friendlyFileName: string;
}