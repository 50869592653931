﻿import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseDataService } from './shared/base.dataservice';
import { ResultEntity, Applicant } from './shared/models';

@Injectable()
export class AppDataService {
    private _apiPath = "/api/";

    constructor(private _base: BaseDataService) { }

    getSavedApplication(lastName: string, phone: string): Observable<ResultEntity> {
        phone = phone.split('.').join('');
        return this._base.get<ResultEntity>(this._apiPath + "WAJobApp/" + lastName + "/" + phone);
    }

    getCertifications(): Observable<ResultEntity> {
        return this._base.get<ResultEntity>(this._apiPath + "WACertification");
    }

    getCompanies(): Observable<ResultEntity> {
        return this._base.get<ResultEntity>(this._apiPath + "WACompany");
    }

    getJobTitles(): Observable<ResultEntity> {
        return this._base.get<ResultEntity>(this._apiPath + "WAJobTitle");
    }

    getPositions(): Observable<ResultEntity> {
        return this._base.get<ResultEntity>(this._apiPath + "WAPosition");
    }

    getStates(): Observable<ResultEntity> {
        return this._base.get<ResultEntity>(this._apiPath + "WAState");
    }

    saveApplication(applicant: Applicant): Observable<ResultEntity> {
        return this._base.post<ResultEntity>(this._apiPath + "WAJobApp", applicant);
    }

    uploadFile(formData: FormData, uploadType: number): Observable<ResultEntity> {
        var sType: string = "Resume";
        if (uploadType == 1) {
            sType = "Cert";
        }
        return this._base.post<ResultEntity>(this._apiPath + "WAFileUpload/" + sType, formData);
    }
}
