﻿import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BaseDataService {
    constructor(private _http: HttpClient) { }

    get<T>(url: string): Observable<T> {
        return this._http.get<T>(url);
    }
    post<T>(url: string, body: Object, logError: boolean = true): Observable<T> {
        return this._http.post<T>(url, body);
    }

}
